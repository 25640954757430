import React from 'react';
const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const MenuItem = ({ item, currency, isWithinHours }) => (
    <div className={`bg-gray-50 rounded-lg shadow-md p-4 flex flex-col ${!isWithinHours && 'opacity-50'}`} key={item.name}>
      <div className="flex items-start">
        <img src={item.image} alt={item.name} className={`w-16 h-16 object-cover rounded-lg mr-4 ${!isWithinHours && 'grayscale'}`} />
        <div className="gap-y-1 flex flex-col flex-1">
          <div className="flex flex-row items-center">
            <div className="flex items-center">
              {item.new && <span className="bg-yellow-300 text-yellow-900 font-semibold text-xs font-mono px-2 py-1 rounded-full mr-2">NEW</span>}
              {item.favorite && <span className="bg-green-300 text-green-900 font-semibold text-xs font-mono px-2 py-1 rounded-full mr-2">FAV</span>}
              <h3 className="text-base font-semibold text-gray-900">{item.name}</h3>
            </div>
          </div>
          <p className="text-sm text-gray-800">{item.description}</p>
            <div className="text-sm flex flex-row">
              <div className="flex flex-wrap gap-1 mt-1">
                {item.allergens.map((allergen) => (
                  <img
                    key={allergen}
                    src={`static/img/alergenos/${removeAccents(allergen.toLowerCase())}.png`}
                    alt={allergen}
                    title={allergen}
                    className="w-4 h-4"
                  />
                ))}
              </div>
              <div className="text-xs gap-x-1 ml-auto text-gray-400 flex flex-row items-center">
                {item.calories && (
                  <p className="text-xs text-gray-400 flex items-center">
                    <span>{item.calories} cals</span>
                    <span className="ml-1"> ·</span>
                  </p>
                )}
                <p className="">{item.price}{currency}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
  
  
export default MenuItem;