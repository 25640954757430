import React from 'react';

const handleScrollToCategory = (category) => {
  const categoryElement = document.getElementById(category);
  if (categoryElement) {
    const yOffset = -90;
    const y = categoryElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

const CategoryButtons = ({ categories }) => (
  <div className="overflow-x-scroll overflow-y-hidden sticky top-0 bg-gray-100 py-4 z-10">
    <div className="flex justify-start space-x-4 pl-4 lg:pl-0 text-sm">
      {categories
        .filter((category) => !category.favorite)
        .map((category) => (
          <button
            key={category.name}
            className={`py-1 px-4 h-12 rounded-full shadow-md whitespace-nowrap ${
              category.new ? 'bg-yellow-300' : 'bg-white'
            }`}
            onClick={() => handleScrollToCategory(category.name)}
          >
            {category.name}
          </button>
        ))}
    </div>
  </div>
);



export default CategoryButtons;