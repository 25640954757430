import React from 'react';
import MenuItem from './MenuItem';

const isWithinWorkingHours = (workingHours) => {
  if (!workingHours) return true;
  const now = new Date();
  const start = new Date();
  const end = new Date();
  const [startHour, startMinute] = workingHours.start.split(':');
  const [endHour, endMinute] = workingHours.end.split(':');
  start.setHours(startHour, startMinute, 0, 0);
  end.setHours(endHour, endMinute, 0, 0);
  return now >= start && now <= end;
};

const CategorySection = ({ category, items, currency }) => {
  const isWithinHours = isWithinWorkingHours(category.working_hours);

  return (
    <div key={category.name} id={category.name} className="mb-12">
      {!category.favorite && (
        <h2 className="text-2xl font-semibold mb-4">
          {category.name} {!isWithinHours && <span className="text-sm text-gray-600">(Working Hours: {category.working_hours.start} - {category.working_hours.end})</span>}
        </h2>
      )}
      <div className="space-y-4">
        {items
          .filter((item) => category.favorite ? item.favorite : item.category === category.name)
          .map((item) => (
            <MenuItem key={item.name} item={item} currency={currency} isWithinHours={isWithinHours} />
          ))}
      </div>
    </div>
  );
};

  
export default CategorySection;