import React, { useEffect, useState } from 'react';
import Header from './Header';
import CategoryButtons from './CategoryButtons';
import CategorySection from './CategorySection';

const useMenu = () => {
  const [menu, setMenu] = useState({ categories: [], items: [], title: '', logo: '', currency: '' });

  useEffect(() => {
    fetch('/menu.json')
      .then((response) => response.json())
      .then((data) => {
        const categories = data.categories.map((category) => {
          const hasNewItem = data.items.some((item) => item.category === category.name && item.new);
          return { ...category, new: hasNewItem };
        });

        const favoriteItems = data.items.filter((item) => item.favorite);
        if (favoriteItems.length > 0) {
          categories.unshift({ name: '💚', new: false, favorite: true });
        }

        setMenu({ ...data, categories });
      });
  }, []);

  return menu;
};


const App = () => {
  const menu = useMenu();

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center">
      <div className="max-w-3xl w-full">
        <Header logo={menu.logo} title={menu.title} />
        <CategoryButtons categories={menu.categories} />
        <main className="lg:p-10 p-4 bg-white rounded-t-2xl gap-y-12 flex flex-col">
          {menu.categories.map((category) => (
            <CategorySection key={category.name} category={category} items={menu.items} currency={menu.currency} />
          ))}
        </main>
      </div>
    </div>
  );
};

export default App;
